
export default {
    name: 'AddressPopup',
    components: {},
    props: {
        popup: Object
    },
    data() {
        return {}
    },
    created() {},
    mounted() {},
    methods: {
        handleCancel() {
            this.popup.visible = false
        }
    },
}
