
import AddressEmpty from './AddressEmpty'

export default {
    name: 'AddressListSection',
    components: {
        AddressEmpty
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            skeleton: true,
            visible: false,
            country: {},
            query: {},
            list: [],
            target: {},
            placeAddress: {},
            params: {
                default: {}
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        this.getAddressListAjax()
    },
    methods: {
        // 获取地址列表
        getAddressListAjax() {
            let params = {};
            if(this.query.key == 'order/placeAddress') {
                params.filterTag = true
                params.countryCode = ''
            }
            this.$api.address.getAddressList(params).then(response => {
                const res = response.result || [];
                this.skeleton = false
                this.list = res
                this.visible = !!this.list.length
                this.setData()
            })
        },
        setData() {
            if(this.query.hasFromOrder && this.placeAddress.id) {
                const list = this.list;
                let listArr = [];
                list.forEach(p => {
                    if(this.placeAddress.id == p.id) {
                        this.placeAddress = p
                        this.$storage.set(this.query.key, this.placeAddress)
                    } else {
                        listArr.push(p)
                    }
                })
                listArr.unshift({
                    ...this.placeAddress,
                    hasSelect: true
                })
                this.list = listArr
            }
        },
        // 编辑地址接口
        setAddressDefault() {
            this.$api.address.setEdit(this.params.default).then(response => {
                const res = response.result || [];
                this.getAddressListAjax()
            })
        },
        // 删除地址接口
        deleteAddressAjax() {
            this.$api.address.deleteAddress({
                id: this.target.id
            }).then(response => {
                const res = response.result || [];
                this.getAddressListAjax()
            })
        },
        // 设置默认地址
        handleDefault(obj) {
            if(this.query.hasFromOrder) {
                this.handleList(obj)
                return
            }
            this.target = obj
            this.params.default = {
                country: obj.country,
                firstName: obj.firstName,
                lastName: obj.lastName,
                middleName: obj.middleName,
                phone: obj.phone,
                addressLine1: obj.addressLine1,
                addressLine2: obj.addressLine2 || '',
                postCode: obj.postCode || '',
                email: obj.email,
                state: obj.state,
                city: obj.city || '',
                street: obj.street || '',
                def: !obj.def,
                vatNumber: obj.vatNumber || '',
                nationalId: obj.nationalId || '',
                id: obj.id
            }
            !obj.def && this.setAddressDefault()
        },
        // 编辑
        handleEdit(obj) {
            const key = 'editAddress';
            this.target = obj
            this.$storage.set(key, obj)
            this.$storage.remove('countryStorage')
            this.$router.push({
                name: 'Address',
                query: {
                    key: key
                }
            })
        },
        // 删除
        handleDelete(obj) {
            this.$Dialog.confirm({
                message: `${this.$translate('Are you sure you to delete this address')}?`,
                confirmButtonText: this.$translate('Delete'),
                cancelButtonText: this.$translate('Cancel'),
                cancelButtonColor: '#999999',
                confirmButtonColor: '#333'
            })
            .then(() => {
                this.target = obj
                this.deleteAddressAjax()
            })
            .catch(() => {});
        },
        // 地址列表点击
        handleList(obj) {
            if(this.query.hasFromOrder) {
                this.$storage.set(this.query.key, obj)
                this.$router.back()
            }
        },
        // 初始化
        initialSet() {
            this.query = this.$route.query || {}
            this.country = this.$storage.get('country') || {}
            this.query.hasFromOrder = this.query.key && this.query?.key.includes('order/placeAddress')
            this.placeAddress = this.$storage.get('order/placeAddress') || {}
        }
    },
}
