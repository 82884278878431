
export default {
    name: 'AddressDefault',
    props: {
        login: Boolean,
        form: Object
    },
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
    },
    methods: {},
}
