
export default {
    name: 'AddressBasic',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        check: Object,
        rules: Object,
        query: Object,
        form: Object,
        country: Object
    },
    data() {
        return {
            // country: {},
            showPopover: false,
            popover: {
                visible: false
            },
            email: {
                list: [],
                emailSuffix: []
            },
            list: [],
        }
    },
    computed: {},
    watch: {
        rules: {
            handler(n ,o) {
                this.initialSet()
            },
            deep: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        // 表单提交
        submit() {
            this.check.hasBasic = true
        },
        failed(e) {
            this.$refs.form.scrollToField(e.errors[0].name,false)
            this.check.hasBasic = false
        },
        // 输入
        inputEmail(obj, e, i) {
            this.check.hasChange = true
            // email
            if(obj.key == 'email') {
                if(this.form.email.includes('@')) {
                    return
                }
                this.email.list = []
                this.email.emailSuffix.forEach(item => {
                    this.email.list.push({
                        text: `${this.form.email}${item}`
                    })
                })
                this.popover.visible = true
            }
            this.setSpm(obj, e, i)
        },
        selectEmail(e) { // email选择
            this.form.email = e.text
            this.popover.visible = false
        },
        // 设置
        handleSet(obj) {
            this.$emit('emit-handle-set', obj)
        },
        handleSetFull(obj) {
            if(obj.hasFullHot) {
                this.$emit('emit-handle-set', obj)
            }
        },
        // spm
        setSpm(obj, e, i) {
            const spmObj = {
                d: i + 1,
                g: {
                    pageId: this.form[obj.key]
                }
            };
            this.$fnApi.debounce(() => {
                this.$ctxApi.spm.initial(spmObj, this)
            })
        },
        // 初始化设置
        initialSet() {
            // this.country = this.$storage.get('country') || {};
            // this.email.emailSuffix = ['@gmail.com', '@yahoo.com', '@hotmail.com', '@icloud.com', '@msn.com', '@live.com', '@aol.com', '@outlook.com', '@opera.com']
            this.list = [{
                list: [{
                    title: this.$translate('Country/Region'),
                    key: 'englishName',
                    require: true,
                    readonly: true,
                    hasFullHot: true,
                    status: true,
                    icon: 'icon-right',
                    class: 'AddressBasic-form-englishName'
                }]
            }, {
                list: [{
                    title: this.$translate('First Name'),
                    key: 'firstName',
                    class: 'AddressBasic-form-firstName',
                    require: true,
                    status: true,
                    msg: this.rules.firstNameErrorMessage,
                    validator: (val) => {
                        return /^.{2,34}$/.test(val);
                    }
                }]
            }, {
                hasVisible: !this.rules.middleNameShow,
                list: [{
                    title: this.$translate('Middle Name'),
                    key: 'middleName',
                    class: 'AddressBasic-form-middleName',
                    require: this.rules.middleNameRequired,
                    status: true,
                    msg: this.rules.middleNameErrorMessage,
                    validator: (val) => {
                        return new RegExp(this.rules.middleNameRegex).test(val);
                    }
                }]
            }, {
                list: [{
                    title: this.$translate('Last Name'),
                    key: 'lastName',
                    class: 'AddressBasic-form-lastName',
                    require: true,
                    status: true,
                    msg: this.rules.lastNameErrorMessage,
                    validator: (val) => {
                        return /^.{2,34}$/.test(val);
                    }
                }]
            }, {
                list: [{
                    title:  this.country.countryCode=='AU'?this.$translate('Phone Number(Optional)'):this.$translate('Phone Number'),
                    key: 'phone',
                    require: this.country.countryCode != 'AU',
                    type: 'number',
                    // icon: 'icon-question',
                    msg: this.rules.mobileErrorMessages,
                    hasPopover: false,
                    hideIcon: true,
                    status: true,
                    label: `${this.country.countryCode}+${this.rules.mobileCode}`,
                    class: 'AddressBasic-phone',
                    max: +this.rules.mobileLength || 20,
                    desc: this.$translate('May be used to assist delivery'),
                    tip: this.$translate('In case we need to contact you about your order'),
                    validator: (val) => {
                        return new RegExp(this.rules.mobileRegex).test(val);
                    }
                }]
            }, {
                hasVisible: !this.rules.alternatePhoneShow,
                list: [{
                    title: this.$translate('Alternate Phone Number'),
                    key: 'alternatePhone',
                    require: this.rules.alternatePhoneRequired,
                    type: 'number',
                    class: 'AddressBasic-form-alternate',
                    msg: this.rules.alternatePhoneErrorMessage,
                    status: true,
                    max: +this.rules.mobileLength || 20,
                    class: 'AddressBasic-phone',
                    label: `${this.country.countryCode}+${this.rules.mobileCode}`,
                    validator: (val) => {
                        return new RegExp(this.rules.alternatePhoneRegex).test(val);
                    }
                }]
            }, {
                hasVisible: !(!this.$login() && !this.query.bill && !['order/billAddress'].includes(this.query.key)),
                list: [{
                    title: this.$translate('Email'),
                    key: 'email',
                    class: 'AddressBasic-form-email',
                    require: true,
                    status: true,
                    msg: this.$translate('Invalid email address'),
                    validator: (val) => {
                        return /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(val);
                    }
                }]
            }]
        }
    }
}
