
import AddressListSection from './components/AddressListSection'

export default {
    name: 'addressList',
    components: {
        AddressListSection
    },
    data() {
        return {
            title: '',
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        jumpAddress() {
            this.$storage.remove('countryStorage')
            this.$router.push({
                name: 'Address'
            })
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('Shipping Address'),
                keywords: this.$translate('Shipping Address'),
                description: this.$translate('Shipping Address'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
        // 初始化
        initialSet() {
            this.title = this.$translate('My Address')
        }
    },
}
